import axios from 'axios'
import { stringify as queryStringify } from './query_params'
import { fetchUsersByType } from '../pages/requests/store/requests/filters'
import { globalModule } from '../store/globalModule'

const baseUrl = () => {
  // If this is called within the Vue app we can get the proxy URL from vuex state, but if not then it will be set as a global.
  const account = globalModule.state.account
  return account ? account.prizmdoc_proxy_url : window.prizmdoc_proxy_url
}

export default {
  // PAS Markup Layer CRUD
  deleteMarkupLayer(sessionId, layerId) {
    return axios({
      method: 'delete',
      url: `${baseUrl()}/MarkupLayers/u${sessionId}/${layerId}`
    })
  },

  getMarkupLayer(viewingSessionId, layerId) {
    if (!layerId) {
      return Promise.reject(new Error('getMarkupLayer called without layerId'))
    }
    return axios({
      method: 'get',
      url: `${baseUrl()}/MarkupLayers/u${viewingSessionId}/${layerId}`
    })
  },

  getMarkupLayers(sessionId) {
    return axios({
      method: 'get',
      url: `${baseUrl()}/MarkupLayers/u${sessionId}`
    })
  },

  newMarkupLayer(payload, sessionId) {
    return axios({
      method: 'post',
      url: `${baseUrl()}/MarkupLayers/u${sessionId}`,
      data: payload
    })
  },

  // PAS Viewing Session
  newViewingSession(docId, docS3Url, documentExtension) {
    return axios({
      method: 'post',
      url: `${baseUrl()}/ViewingSession`,
      data: JSON.stringify({
        source: {
          type: 'url',
          url: docS3Url,
          fileExtension: documentExtension,
          markupId: docId.toString(),
          headers: {
            'user-agent': 'PrizmShare'
          }
        }
      })
    })
  },

  // Page attributes
  getPageAttributes(sessionId, pageNumber) {
    return axios({
      method: 'get',
      url: `${baseUrl()}/Page/q/${pageNumber}/Attributes?DocumentID=u${sessionId}`
    })
  },

  // Application Endpoints

  setPermissionUserPreview(params) {
    return axios({
      method: 'post',
      url: '/client/users/preview',
      params
    })
  },
  batchDraftRedact(payload) {
    return axios('/batch_draft_redact.json', {
      method: 'post',
      data: payload
    })
  },

  batchStatusUpdate(payload) {
    return axios('/document_review/batch_status_update', {
      method: 'put',
      data: {
        doc_ids: payload.docIds,
        review_state: payload.reviewState,
        pretty_id: payload.prettyId
      }
    })
  },

  burnMultipleDraftRedactions(payload) {
    return axios({
      method: 'post',
      url: '/burn_multiple_draft_redactions',
      data: payload
    })
  },

  deleteRedactionLayer(ids) {
    const redactionParams = { document_id: ids }
    return axios({
      method: 'delete',
      url: '/delete_redaction_layer',
      data: redactionParams
    })
  },

  fetchRedactionReasons() {
    return axios('/admin/redaction_reasons', {
      method: 'get'
    })
  },

  getRedactionLayer(docId) {
    return axios({
      method: 'get',
      url: '/redaction_layer',
      params: {
        document_id: docId
      }
    })
  },

  jobStatuses(params) {
    return axios({
      method: 'get',
      url: '/background_job_logs',
      params
    })
  },

  sidekiqStatuses(params) {
    return axios({
      method: 'get',
      url: '/sidekiq_job_statuses',
      params
    })
  },

  extraction(docId) {
    return axios({
      method: 'post',
      url: '/extraction/',
      data: {
        document_id: docId
      }
    })
  },

  s3Url(docId) {
    return axios('/s3_url', {
      method: 'get',
      params: {
        document_id: docId
      }
    })
  },

  updateRedactionLayer(payload) {
    return axios({
      method: 'put',
      url: '/update_redaction_layer',
      data: payload
    })
  },

  unzipFiles(docId) {
    return axios(`/zip_files/${docId}`, {
      method: 'get'
    })
  },

  zipFiles(payload) {
    return axios('/zip_files/', {
      method: 'post',
      data: {
        doc_ids: payload.docIds,
        pretty_id: payload.prettyId
      }
    })
  },

  // Client API endpoints
  account() {
    return axios('/client/account', {
      method: 'get'
    })
  },

  updateAccount(payload) {
    return axios('/client/account', {
      method: 'put',
      data: payload
    })
  },

  updateAccountState(payload) {
    return axios('/admin/change_state', {
      method: 'put',
      data: payload
    })
  },

  deleteDocuments() {
    return axios({
      method: 'delete',
      url: '/client/documents/delete'
    })
  },

  deleteRequests() {
    return axios({
      method: 'delete',
      url: '/client/requests/delete'
    })
  },

  ocrDocument(id) {
    return axios({
      method: 'post',
      url: `/client/documents/${id}/ocr`
    })
  },

  fetchAbilityDocuments(params) {
    return axios({
      method: 'get',
      url: '/client/documents/ability',
      params
    })
  },

  // update this for ability of requests
  fetchAbilityRequests() {
    return axios({
      method: 'get',
      url: '/client/documents/ability'
    })
  },

  // update this for ability of request show
  fetchAbilityRequestShow() {
    return axios({
      method: 'get',
      url: '/client/documents/ability'
    })
  },

  fetchRequestFields(payload) {
    return axios({
      method: 'get',
      url: '/client/request_fields',
      params: payload
    })
  },

  publishDraftFields(stateTimestamp) {
    return axios({
      method: 'post',
      url: '/client/request_fields/publish',
      params: { state_timestamp: stateTimestamp }
    })
  },

  resetDraftFields(stateTimestamp) {
    return axios({
      method: 'post',
      url: '/client/request_fields/reset',
      params: { state_timestamp: stateTimestamp }
    })
  },

  deleteDraftField(draftId) {
    return axios({
      method: 'delete',
      url: `/client/request_fields/${draftId}`
    })
  },

  copyDraftField(draftId) {
    return axios({
      method: 'post',
      url: `/client/request_fields/${draftId}/copy_draft_field`
    })
  },

  updateRequestField(draftId, field) {
    return axios({
      method: 'put',
      url: `/client/request_fields/${draftId}`,
      data: field
    })
  },

  createRequestField(field) {
    return axios({
      method: 'post',
      url: '/client/request_fields',
      data: field
    })
  },

  fetchOfficeClosureDates(payload) {
    return axios({
      method: 'get',
      url: '/client/office_closure_dates',
      params: payload
    })
  },

  createOfficeClosureDate(payload) {
    return axios({
      method: 'post',
      url: '/client/office_closure_dates',
      data: payload
    })
  },

  updateOfficeClosureDate({ id, ...payload }) {
    return axios({
      method: 'put',
      url: `/client/office_closure_dates/${id}`,
      data: payload
    })
  },

  deleteOfficeClosureDate(id) {
    return axios({
      method: 'delete',
      url: `/client/office_closure_dates/${id}`
    })
  },

  fetchDocumentList(payload) {
    return axios({
      method: 'get',
      url: '/client/documents',
      params: payload
    })
  },

  fetchRequestsListLastCancelTokenSource: axios.CancelToken.source(),

  fetchRequestsList(payload) {
    this.fetchRequestsListLastCancelTokenSource.cancel('canceled')
    this.fetchRequestsListLastCancelTokenSource = axios.CancelToken.source()
    return axios({
      method: 'get',
      url: '/client/requests',
      params: payload,
      paramsSerializer: queryStringify,
      cancelToken: this.fetchRequestsListLastCancelTokenSource.token
    })
  },

  fetchRequest(prettyId) {
    return axios({
      method: 'get',
      url: `/client/requests/${prettyId}`
    })
  },

  fetchRequestEmailStatuses(prettyId) {
    return axios({
      method: 'get',
      url: `/client/requests/${prettyId}/requester_email_statuses`
    })
  },

  fetchRequestDocuments(params) {
    return axios({
      method: 'get',
      params,
      url: '/client/request_documents'
    })
  },

  fetchRequestDocumentsFolders(params) {
    return axios({
      method: 'get',
      params,
      url: `/client/requests/${params.request_id}/folders`
    })
  },

  fetchRequestDocumentsChildFolders(params) {
    return axios({
      method: 'get',
      url: `/client/requests/${params.request_id}/folders/${params.folder_id}/child_folders`,
      params
    })
  },

  fetchRequestDocumentRisk(params) {
    return axios({
      method: 'get',
      url: '/client/request_documents/scans',
      params
    })
  },

  fetchRequestStaffHours(params) {
    return axios({
      method: 'get',
      url: `/client/requests/${params.pretty_id}/request_hours`,
      params
    })
  },

  setTimelinePin(params) {
    return axios({
      method: 'put',
      url: '/client/notifications',
      params
    })
  },

  fetchRequestTimeline(payload) {
    return axios({
      method: 'get',
      url: `/client/requests/${payload.pretty_id}/timeline`,
      params: {
        filter_categories: payload.filter_categories,
        page_number: payload.page_number
      }
    })
  },

  fetchReportFile(payload) {
    return axios({
      method: 'get',
      url: '/client/requests/download',
      params: payload
    })
  },

  downloadReportFile(url) {
    return axios({
      method: 'get',
      url,
      responseType: 'arraybuffer'
    })
  },

  savedViews() {
    return axios({
      method: 'get',
      url: '/client/saved_filters'
    })
  },

  previewSwitch(bool) {
    return axios({
      method: 'put',
      url: '/client/users/beta_switch',
      params: { request_show_beta: bool }
    })
  },

  addSavedViews(payload) {
    return axios({
      method: 'post',
      url: '/client/saved_filters',
      data: payload
    })
  },

  deleteSavedViews(id) {
    return axios({
      method: 'delete',
      url: `/client/saved_filters/${id}`
    })
  },

  fetchRequestsFilters(payload) {
    const {
      type,
      api_param: apiParam,
      name,
      page_number: pageNum,
      res_key: resKey
    } = payload
    return axios({
      method: 'get',
      url: `/client/requests/${type}?search_text=${name}&page_number=${pageNum}${
        apiParam !== 'requesters' ? '&poc=' + (resKey === 'poc') : ''
      }`
    })
  },

  saveDocs(payload) {
    return axios({
      method: 'post',
      url: '/client/documents',
      data: payload
    })
  },

  // connect this foor saving new filter views
  saveViews(payload) {
    return axios({
      method: 'post',
      url: '/client/requests',
      data: payload
    })
  },

  signOut() {
    return axios({
      method: 'delete',
      url: '/users/sign_out'
    })
  },

  generateReport(payload) {
    return axios({
      method: 'post',
      url: '/client/requests/export',
      data: payload
    })
  },

  fetchSavedReports() {
    return axios({
      method: 'get',
      url: '/client/csv_layouts'
    })
  },

  fetchPreviewUsers(payload) {
    return axios({
      method: 'get',
      url: `/client/users/preview`,
      params: payload
    })
  },

  fetchStaff(payload) {
    return fetchUsersByType({ type: 'staff', ...payload })
  },

  fetchRequestTags(id) {
    return axios({
      method: 'get',
      url: `/client/requests/${id}/tags`
    })
  },

  fetchRequestTasks(payload) {
    return axios({
      method: 'get',
      url: `/client/requests/${payload.pretty_id}/tasks`,
      params: payload.params
    })
  },

  fetchRequestInvoices(id) {
    return axios({
      method: 'get',
      url: `/client/requests/${id}/invoices`
    })
  },

  fetchStaffDepartments(payload) {
    return axios({
      method: 'get',
      url: '/client/departments',
      params: payload
    })
  },

  bulkDocumentAction(payload) {
    return axios({
      method: 'put',
      url: '/client/documents/bulk',
      data: payload
    })
  },

  fetchDocFiles(payload) {
    return axios({
      method: 'get',
      url: '/client/documents/download',
      params: payload
    })
  },

  submitExternalMessage(payload) {
    return axios({
      method: 'post',
      url: '/client/notifications',
      data: payload
    })
  },

  submitInternalMessage(payload) {
    return axios({
      method: 'post',
      url: '/client/notifications',
      data: payload
    })
  },

  submitNewTask(payload) {
    const method = payload.action_type === 'edit' ? 'put' : 'post'
    return axios({
      method,
      url: `/client/requests/${payload.request_id}/tasks`,
      data: payload
    })
  },

  submitRequestStaffTime(payload) {
    return axios({
      method: payload.method,
      url: `/client/requests/${payload.request_id}/request_hours`,
      data: payload
    })
  },

  /* eslint-disable camelcase */
  submitStaffChange(payload) {
    // supports 'create' and 'delete' methods
    const { id, request_id, method } = payload
    return axios({
      method: method,
      url: `/client/requests/${request_id}/staff/${id || ''}`,
      data: payload
    })
  },

  messageTemplates(payload) {
    return axios({
      method: 'get',
      url: `/client/accounts/message_templates`,
      params: payload
    })
  },

  invoiceTemplates(payload) {
    return axios({
      method: 'get',
      url: `/client/accounts/invoice_templates`,
      params: payload
    })
  },

  sendNotification(payload) {
    return axios({
      method: 'post',
      url: '/client/notifications',
      data: payload
    })
  },

  updateFolder(payload, deleteMethod) {
    const method = deleteMethod ? 'delete' : 'put'
    return axios({
      method: method,
      url: '/client/documents_folders_request',
      data: payload
    })
  },

  updateDocTitle(payload) {
    return axios({
      method: 'put',
      url: `/client/documents/${payload.id}`,
      data: payload
    })
  },

  updateMessage(payload) {
    return axios({
      method: 'put',
      url: '/client/notifications',
      data: payload
    })
  },

  deleteTask(payload) {
    return axios({
      method: 'delete',
      url: `/client/requests/${payload.request_id}/tasks`,
      data: payload
    })
  },

  fetchInvoiceCharges() {
    return axios({
      method: 'get',
      url: '/client/accounts/invoice_charges_templates'
    })
  },

  processInvoice(payload) {
    // supports 'post', 'put', and 'delete' methods
    return axios({
      method: payload.method,
      url: `/client/requests/${payload.request_id}/invoices`,
      data: payload
    })
  },

  processStaffHour(payload) {
    // supports 'put', and 'delete' methods
    return axios({
      method: payload.action,
      url: `/client/requests/${payload.request_id}/request_hours`,
      data: payload
    })
  },

  updateRequest(payload) {
    return axios({
      method: 'PUT',
      url: `/client/requests/${payload.request_id}`,
      data: payload
    })
  },

  updateFieldValue(requestId, id, value) {
    return axios.put(
      `/client/requests/${requestId}/request_field_values/${id}`,
      {
        request_field_value: { value }
      }
    )
  },

  createFieldValue(requestId, requestFieldVersionId, value) {
    return axios.post(`/client/requests/${requestId}/request_field_values`, {
      request_field_value: { value },
      request_field_version_id: requestFieldVersionId
    })
  },

  // Used only to reset the session timeout
  resetSession() {
    return axios({
      method: 'GET',
      url: '/'
    })
  },

  fetchSearchIntegration(payload) {
    const { account_id } = payload
    return axios({
      method: 'GET',
      url: `/client/accounts/search_integrations?account_id=${account_id}`
    })
  },

  getCsrfToken() {
    return axios({
      method: 'GET',
      url: '/client/csrf_token'
    })
  }
}
